import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import UpArrow from '../Utilities/UpArrow'
import "../Sass/PrivacyPolicy.scss"

const PrivacyPolicy = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        const href = window.location.href.substring(
            window.location.href.lastIndexOf('#') + 1
        );
        const element = document.getElementById(href);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    const [display, setDisplay] = useState("none");

    const show = () => {
        setDisplay("block");
    }

    const hide = () => {
        setDisplay("none");
    }

    const up = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setDisplay("none");
    }
    const [animation, setAnimation] = useState("zoom-in-zoom-out 2s ease-out 1");

    const down = () => {
        window.scrollTo({ top: 10000, left: 0 });
        setDisplay("none");

        if (animation === "zoom-in-zoom-out 2s ease-out 1") {
            setAnimation("none");
        }
        else {
            setAnimation("zoom-in-zoom-out 2s ease-out 1");
        }
    }
    const hidden = () => {
        setDisplay("none");
    }

    return (
        <div>
            <UpArrow/>
            {/* Header */}
            <header>
                <div className="logo">
                    <img rel='preload' fetchpriority="high" src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699086529/meluktor.com/meluktor_500_logo_noujzc.png" alt="logo" />
                </div>
                <div className="links">
                    <Link to={"/"} onClick={up}>Home</Link>
                    <Link onClick={hidden} to={"/#about"}>About Meluktor-500</Link>
                    <a href="#contact-us" onClick={down}>Contact Us</a>
                    <Link onClick={hidden} to={"/#buy"}>Buy Now</Link>
                </div>

                <div id='navbar-mobile' style={{ display: display }}>
                    <svg className='cross' onClick={hide} id="Capa_1" enable-background="new 0 0 320.591 320.591" height="512" viewBox="0 0 320.591 320.591" width="512" xmlns="http://www.w3.org/2000/svg"><g><g id="close_1_"><path d="m30.391 318.583c-7.86.457-15.59-2.156-21.56-7.288-11.774-11.844-11.774-30.973 0-42.817l257.812-257.813c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875l-259.331 259.331c-5.893 5.058-13.499 7.666-21.256 7.288z" /><path d="m287.9 318.583c-7.966-.034-15.601-3.196-21.257-8.806l-257.813-257.814c-10.908-12.738-9.425-31.908 3.313-42.817 11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414-6.35 5.522-14.707 8.161-23.078 7.288z" /></g></g></svg>

                    <div className="links">
                        <Link to={"/"} onClick={up}>Home</Link>
                        <Link onClick={hidden} to={"/#about"}>About Meluktor-500</Link>
                        <a href="#contact-us" onClick={down}>Contact Us</a>
                        <Link onClick={hidden} to={"/#buy"}>Buy Now</Link>

                    </div>
                </div>
                <svg className='menu' onClick={show} id="Layer_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m512 256.002c0 17.673-14.327 32-32 32h-307.059c-17.673 0-32-14.327-32-32s14.327-32 32-32h307.059c17.673 0 32 14.327 32 32zm-339.059-134.55h307.059c17.673 0 32-14.327 32-32s-14.327-32-32-32h-307.059c-17.673 0-32 14.327-32 32s14.328 32 32 32zm307.059 269.096h-307.059c-17.673 0-32 14.327-32 32s14.327 32 32 32h307.059c17.673 0 32-14.327 32-32s-14.327-32-32-32zm-419.195-333.096h-28.805c-17.673 0-32 14.327-32 32s14.327 32 32 32h28.805c17.673 0 32-14.327 32-32s-14.327-32-32-32zm0 166.551h-28.805c-17.673 0-32 14.327-32 32s14.327 32 32 32h28.805c17.673 0 32-14.327 32-32s-14.327-32-32-32zm0 166.542h-28.805c-17.673 0-32 14.327-32 32s14.327 32 32 32h28.805c17.673 0 32-14.327 32-32s-14.327-32-32-32z" /></svg>

            </header>
            {/* Header Ends */}

            {/* Privacy Policy */}
            <div className="privacy-policy">

                <div className="section-title">
                    <h2>Privacy Policy</h2>
                </div>

                <p>Last Updated: 5 DEC 2023</p>
                <p>Welcome to Akcent Healthcare Pvt Ltd ("us", "we", or "our"). Your privacy is important to us. This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal information when you visit our website.</p>

                <ol>
                    <li>Information We Collect:</li>

                    <ul>
                        <li>Personal Information: <br />
                            <p>We may collect personally identifiable information, such as your name, contact details, email address, quantity, etc when you voluntarily submit it through our contact forms or other interactions.</p>
                        </li>

                        <li>Usage Data:<br />
                            <p>We may collect information on how the website is accessed and used ("Usage Data"). This data may include your computer's Internet Protocol address, browser type, browser version, the pages you visit, the time and date of your visit, and other diagnostic data.</p>
                        </li>
                    </ul>

                    <li>How We Use Your Information</li>

                    <ul>
                        <li>Personal Information: <br />
                            <p>We may use your personal information to contact you with newsletters, marketing, or promotional materials, and other information that may be of interest to you.</p>
                        </li>

                        <li>Usage Data:<br />
                            <p>We use Usage Data to analyze and improve the functionality and content of our website, to understand user preferences, and to enhance user experience.</p>
                        </li>
                    </ul>

                    <li>Cookies and Tracking Technologies</li>

                    <ul>
                        <li>Cookies: <br />
                            <p>We use cookies to track the activity on our website and hold certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.</p>
                        </li>
                    </ul>

                    <li>Third-Party Links</li>

                    <ul>
                        <li>
                            External Websites:<br />
                            <p>Our website may contain links to external sites that are not operated by us. We have no control over and assume no responsibility for, the content, privacy policies, or practices of any third-party sites.</p>
                        </li>

                    </ul>

                    <li>Data Security</li>

                    <ul>
                        <li>
                            Security Measures:<br />
                            <p>We employ industry-standard security measures to protect your personal information. However, no method of transmission over the Internet or electronic storage is 100% secure.</p>
                        </li>

                    </ul>

                    <li>Changes to This Privacy Policy</li>

                    <ul>
                        <li>
                            Updates:<br />
                            <p>We reserve the right to update our Privacy Policy periodically. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
                        </li>

                    </ul>

                    <li>Contact Us</li>

                    <ul>
                        <li>
                            Questions:<br />
                            <p>If you have any questions or concerns about our Privacy Policy, please contact us at <a href="mailto:akcenthealthcare@gmail.com">akcenthealthcare@gmail.com</a> </p>
                        </li>

                    </ul>

                </ol>
                <p>By using our website, you agree to the collection and use of information by following this Privacy Policy. </p>
                <p>Please read this Privacy Policy carefully and ensure that you understand it. Your continued use of our website constitutes your acceptance of this Privacy Policy and any updates.</p>
            </div>
            {/* Privacy Policy Ends*/}

            {/* Footer */}
            <footer>
                <div className="footer-body">
                    <div className="footer-about">
                        <h2>Meluktor-500</h2>
                        <p>Meluktor 500 Tablet is a dietary supplement that helps protect the heart and circulatory system.
                            Resveratrol has antioxidant and anti-inflammatory properties and Quercetin has anti-inflammatory
                            and antihistamine effects. It protects against heart and other chronic diseases.</p>
                    </div>
                    <div className="footer-links">
                        <h4>useful Links</h4>
                        <div className="footer-links-content">
                            <Link href="/"><svg id="Layer_1" enable-background="new 0 0 100 100" height="512" viewBox="0 0 100 100" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m16.1 45c2.8 0 5 2.2 5 5s-2.2 5-5 5-5-2.2-5-5 2.2-5 5-5z" /><path d="m26.1 20c2.8 0 5 2.2 5 5s-2.2 5-5 5-5-2.2-5-5 2.2-5 5-5z" /><path d="m21.1 75c0-2.8 2.2-5 5-5s5 2.2 5 5-2.2 5-5 5-5-2.2-5-5z" /><path d="m43 55.6-6.6 26.6c-.7 2.7 2.4 4.8 4.6 3.2l46.6-32.9c1.7-1.2 1.7-3.7 0-4.9l-46.6-32.9c-2.3-1.6-5.3.5-4.6 3.2l6.6 26.5c.3 1.3 1.5 2.3 2.9 2.3h7.2c1.1 0 2.1-.5 2.6-1.5 1.7-3.2 5.1-5.3 9-5.2 5.2.1 9.5 4.5 9.7 9.7.2 5.7-4.4 10.3-10 10.3-3.8 0-7-2.1-8.7-5.2-.5-.9-1.5-1.5-2.6-1.5-2 0-5.1 0-7.2 0-1.3 0-2.5 1-2.9 2.3z" /></svg>&emsp;Home</Link>

                            <Link to={"/#about"}><svg id="Layer_1" enable-background="new 0 0 100 100" height="512" viewBox="0 0 100 100" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m16.1 45c2.8 0 5 2.2 5 5s-2.2 5-5 5-5-2.2-5-5 2.2-5 5-5z" /><path d="m26.1 20c2.8 0 5 2.2 5 5s-2.2 5-5 5-5-2.2-5-5 2.2-5 5-5z" /><path d="m21.1 75c0-2.8 2.2-5 5-5s5 2.2 5 5-2.2 5-5 5-5-2.2-5-5z" /><path d="m43 55.6-6.6 26.6c-.7 2.7 2.4 4.8 4.6 3.2l46.6-32.9c1.7-1.2 1.7-3.7 0-4.9l-46.6-32.9c-2.3-1.6-5.3.5-4.6 3.2l6.6 26.5c.3 1.3 1.5 2.3 2.9 2.3h7.2c1.1 0 2.1-.5 2.6-1.5 1.7-3.2 5.1-5.3 9-5.2 5.2.1 9.5 4.5 9.7 9.7.2 5.7-4.4 10.3-10 10.3-3.8 0-7-2.1-8.7-5.2-.5-.9-1.5-1.5-2.6-1.5-2 0-5.1 0-7.2 0-1.3 0-2.5 1-2.9 2.3z" /></svg>&emsp;About Meluktor-500</Link>

                            <Link to={"/#buy"}><svg id="Layer_1" enable-background="new 0 0 100 100" height="512" viewBox="0 0 100 100" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m16.1 45c2.8 0 5 2.2 5 5s-2.2 5-5 5-5-2.2-5-5 2.2-5 5-5z" /><path d="m26.1 20c2.8 0 5 2.2 5 5s-2.2 5-5 5-5-2.2-5-5 2.2-5 5-5z" /><path d="m21.1 75c0-2.8 2.2-5 5-5s5 2.2 5 5-2.2 5-5 5-5-2.2-5-5z" /><path d="m43 55.6-6.6 26.6c-.7 2.7 2.4 4.8 4.6 3.2l46.6-32.9c1.7-1.2 1.7-3.7 0-4.9l-46.6-32.9c-2.3-1.6-5.3.5-4.6 3.2l6.6 26.5c.3 1.3 1.5 2.3 2.9 2.3h7.2c1.1 0 2.1-.5 2.6-1.5 1.7-3.2 5.1-5.3 9-5.2 5.2.1 9.5 4.5 9.7 9.7.2 5.7-4.4 10.3-10 10.3-3.8 0-7-2.1-8.7-5.2-.5-.9-1.5-1.5-2.6-1.5-2 0-5.1 0-7.2 0-1.3 0-2.5 1-2.9 2.3z" /></svg>&emsp;Buy</Link>

                            <a onClick={down} href="#contact"><svg id="Layer_1" enable-background="new 0 0 100 100" height="512" viewBox="0 0 100 100" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m16.1 45c2.8 0 5 2.2 5 5s-2.2 5-5 5-5-2.2-5-5 2.2-5 5-5z" /><path d="m26.1 20c2.8 0 5 2.2 5 5s-2.2 5-5 5-5-2.2-5-5 2.2-5 5-5z" /><path d="m21.1 75c0-2.8 2.2-5 5-5s5 2.2 5 5-2.2 5-5 5-5-2.2-5-5z" /><path d="m43 55.6-6.6 26.6c-.7 2.7 2.4 4.8 4.6 3.2l46.6-32.9c1.7-1.2 1.7-3.7 0-4.9l-46.6-32.9c-2.3-1.6-5.3.5-4.6 3.2l6.6 26.5c.3 1.3 1.5 2.3 2.9 2.3h7.2c1.1 0 2.1-.5 2.6-1.5 1.7-3.2 5.1-5.3 9-5.2 5.2.1 9.5 4.5 9.7 9.7.2 5.7-4.4 10.3-10 10.3-3.8 0-7-2.1-8.7-5.2-.5-.9-1.5-1.5-2.6-1.5-2 0-5.1 0-7.2 0-1.3 0-2.5 1-2.9 2.3z" /></svg>&emsp;Contact</a>
                        </div>
                    </div>
                    <div className="footer-contact" style={{ animation: animation }}>
                        <h4>Contact info</h4>

                        <div className="footer-contact-body">
                            <div className="footer-contact-content">
                                <div className="svg">
                                    <svg height="512" viewBox="0 0 64 64" width="512" xmlns="http://www.w3.org/2000/svg"><g id="Call"><path d="m52.5 42.85-2.83-2.82a5.9873 5.9873 0 0 0 -8.48 0l-1.27 1.26a1.9573 1.9573 0 0 1 -1.83.54c-2.1-.47-5.49-1.92-9.74-6.17-4.26-4.26-5.71-7.65-6.18-9.75a1.9573 1.9573 0 0 1 .54-1.83l1.27-1.27a6.01768 6.01768 0 0 0 0-8.48l-2.83-2.83a6.01143 6.01143 0 0 0 -8.48 0l-.87.87a10.64659 10.64659 0 0 0 -2.89 9.89c1.49 6.71 6.41 15.28 11.98 20.85 5.57 5.56 14.14 10.49 20.84 11.98a10.96437 10.96437 0 0 0 2.34.26 10.68538 10.68538 0 0 0 7.56-3.15l.87-.87a6.01143 6.01143 0 0 0 0-8.48z" /></g></svg>
                                </div>
                                <a href="tel:+91-1145009554">+91-1145009554</a>
                            </div>
                            <div className="footer-contact-content">
                                <div className="svg">
                                    <svg viewBox="0 -77 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m490.753906 0h-469.507812l234.753906 234.453125zm0 0" /><path d="m0 21.179688v316.445312l158.425781-158.222656zm0 0" /><path d="m256 276.851562-76.347656-76.25-158.40625 158.203126h469.507812l-158.40625-158.203126zm0 0" /><path d="m353.574219 179.402344 158.425781 158.222656v-316.445312zm0 0" /></svg>
                                </div>
                                <a href="mailto:akcenthealthcare@gmail.com" style={{ textTransform: 'lowercase' }}>akcenthealthcare@gmail.com</a>
                            </div>
                            <div className="footer-contact-content">
                                <div className="svg">
                                    <svg id="Layer_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m256 0c-105.6 0-191.5 85.9-191.5 191.5 0 34.4 16.2 77.8 49.4 132.7 27.6 45.6 61.8 90.9 91.9 130.8 13.9 18.4 27 35.8 37.1 50.2 3 4.3 7.9 6.8 13.1 6.8s10.1-2.5 13.1-6.8c10.1-14.4 23.2-31.8 37.1-50.2 30.1-39.9 64.2-85.2 91.9-130.8 33.2-54.9 49.4-98.3 49.4-132.7 0-105.6-85.9-191.5-191.5-191.5zm0 280c-48.8 0-88.5-39.7-88.5-88.5s39.7-88.5 88.5-88.5 88.5 39.7 88.5 88.5-39.7 88.5-88.5 88.5z" /></svg>
                                </div>
                                <a target='_blank' rel='noreferrer' href="https://www.google.com/maps?ll=28.649983,77.145366&z=12&t=m&hl=en&gl=IN&mapclient=embed&cid=6992852429798267520">7/27 Kriti Nagar Industrial Area New Delhi-110015</a>
                            </div>
                        </div>

                        <div className="footer-follow">

                            <div className="footer-follow-top">
                                <a target="_blank" rel="noreferrer" href="https://www.facebook.com/akcenthealthcaresales/">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" /></svg>
                                </a>

                                <a target="_blank" rel="noreferrer" href="https://twitter.com/AkcentHealthca1">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" /></svg>
                                </a>

                                <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/akcent-healthcare-india-pvt-ltd/about/">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" /></svg></a>
                            </div>
                        </div>

                    </div>
                    <div className="footer-map">
                        <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d28010.600781844332!2d77.145366!3d28.649983000000002!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d033a653f51a9%3A0x610b9a3c34da5a80!2sAkcent%20Healthcare%20(INDIA)%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1700049996399!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>

                <div className="footer-copyright">
                    <p>© Copyright {new Date().getFullYear()}. All Rights Reserved by <a target="_blank" rel="noreferrer" href="https://www.akcenthealthcare.com">Akcent Healthcare Pvt. Ltd.</a></p>
                    <div className="footer-copyright-content">
                        <Link to={"/privacy-policy"}>Privacy Policy</Link>
                    </div>
                </div>
            </footer >
            {/* Footer Ends */}
        </div>
    )
}

export default PrivacyPolicy
